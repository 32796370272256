<template>
  <div>
    <br />
    <br />
    <base-section-heading title="Hotels in the heart of dublin">
      <p>
        Mulligan Hospitality hotels.
      </p>
    </base-section-heading>
    <br />
    <div>
      <br />
      <br />
      <v-container>
        <v-row class="white elevation-3 test">
          <v-col cols="12" md="6">
            <base-section-heading
              align="left"
              subtitle="Mulligan Hospitality Ireland"
              tag="h2"
              title="ABBEY LANE HOTEL"
            >
              Abbey Lane Hotel's is located in the heart of Dublin on Abbey
              Street, a quick walk from O'Connell Street. The Abbey Lane has a
              restaurant and a bar, 21 rooms over 3 floors, Free WiFi and
              flat-screen TVs available in all rooms.
            </base-section-heading>

            <base-btn
              href="https://www.wplunket.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              BOOK NOW
            </base-btn>
          </v-col>

          <v-col cols="12" md="6">
            <v-img
              data-aos="zoom-in"
              data-aos-duration="1000"
              contain
              src="@/assets/ablhotelNEW.jpg"
              class="ml-auto"
              height="400"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="white elevation-3 test">
          <v-col cols="12" md="6">
            <base-section-heading
              align="left"
              subtitle="Mulligan Hospitality Ireland"
              tag="h2"
              title="MULLIGAN HOTEL"
            >
              Ideally located on Dame Street in Dublin, Mulligans Hotel offers
              air-conditioned rooms, a restaurant and a bar, plus only a short
              distance from attractions such as Irish Whiskey Museum, Dublin
              Castle, and Trinity College, Grafton Street.
            </base-section-heading>
            <base-btn
              href="https://www.mulliganhotel.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              BOOK NOW
            </base-btn>
          </v-col>

          <v-col cols="12" md="6">
            <v-img
              data-aos="zoom-in"
              data-aos-duration="1000"
              contain
              src="@/assets/mulliganhotelNEW.jpg"
              class="ml-auto"
              height="400"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="white elevation-3 test">
          <v-col cols="12" md="6">
            <base-section-heading
              align="left"
              subtitle="Mulligan Hospitality Ireland"
              tag="h2"
              title="DANBY HOTEL"
            >
              <span
                >The Danby HotelBuilt in the 1730's, is conveniently situated
                just five minutes from Rosslare Euro Port, minutes from some of
                Wexford's most beautiful sandy beach</span
              >
            </base-section-heading>
            <base-btn
              href="https://www.danbyhotel.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              BOOK NOW
            </base-btn>
          </v-col>

          <v-col cols="12" md="6">
            <v-img
              data-aos="zoom-in"
              data-aos-duration="1000"
              contain
              src="@/assets/danby.png"
              class="ml-auto"
              height="400"
            />
          </v-col>
        </v-row>
      </v-container>
      <br />
      <br />
    </div>
  </div>
</template>

<style scoped>
.test {
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
}
</style>

<script>
export default {
  name: "Hotel"
};
</script>
